@charset "UTF-8";
/*
* @subsection   RD Navbar
*
* @description  Describes style declarations for RD Navbar extension
*
* @author       Evgeniy Gusarov
* @link         https://ua.linkedin.com/pub/evgeniy-gusarov/8a/a40/54a
*/
@-webkit-keyframes rd-navbar-slide-down {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes rd-navbar-slide-down {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes rd-navbar-slide-up {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@keyframes rd-navbar-slide-up {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

/*
* @subsection General Styles
*/
.rd-navbar-wrap, .rd-navbar-static .rd-navbar-dropdown,
.rd-navbar-static .rd-navbar-megamenu,
.rd-navbar-fullwidth .rd-navbar-dropdown,
.rd-navbar-fullwidth .rd-navbar-megamenu, .rd-navbar-fixed .rd-navbar-nav-wrap, .rd-navbar-fixed .rd-navbar-submenu {
  transition: 0.3s all cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.rd-navbar, .rd-navbar.rd-navbar--is-clone {
  display: none;
}

.rd-navbar-fixed,
.rd-navbar-static,
.rd-navbar-fullwidth,
.rd-navbar-sidebar {
  display: block;
}

.rd-navbar--no-transition, .rd-navbar--no-transition * {
  transition: none !important;
}

.rd-navbar-wrap,
.rd-navbar,
.rd-navbar-brand,
.rd-navbar-slogan,
.rd-navbar-dropdown,
.rd-navbar-megamenu,
.rd-navbar-collapse-items,
.brand-name,
.rd-navbar-nav,
.rd-navbar-panel,
.rd-navbar-search-form-input,
.rd-navbar-search-form-submit,
.rd-navbar-search-toggle,
.rd-navbar-live-search-results,
.rd-navbar-search-form {
  transition: .3s all ease;
}

.rd-navbar-collapse-toggle {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;
  line-height: 48px;
  cursor: pointer;
  color: #424343;
  display: none;
}

.rd-navbar-collapse-toggle span {
  top: 50%;
  margin-top: -3px;
}

.rd-navbar-collapse-toggle span, .rd-navbar-collapse-toggle span:before, .rd-navbar-collapse-toggle span:after {
  position: absolute;
  width: 6px;
  height: 6px;
  line-height: 6px;
  text-align: center;
  background: #424343;
  left: 50%;
  margin-left: -3px;
  border-radius: 50%;
  transition: .3s all ease;
}

.rd-navbar-collapse-toggle span:before, .rd-navbar-collapse-toggle span:after {
  content: '';
}

.rd-navbar-collapse-toggle span:before {
  bottom: 100%;
  margin-bottom: 3px;
}

.rd-navbar-collapse-toggle span:after {
  top: 100%;
  margin-top: 3px;
}

.rd-navbar-collapse-toggle.active span {
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
}

.rd-navbar-collapse-toggle.active span:before {
  -webkit-transform: translateY(18px);
  transform: translateY(18px);
}

.rd-navbar-collapse-toggle.active span:after {
  -webkit-transform: translateY(-18px);
  transform: translateY(-18px);
}

.rd-navbar--has-sidebar body {
  padding-left: 270px;
}

.rd-navbar--is-stuck {
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.14);
}

.rd-navbar.rd-navbar-fixed + .rd-navbar.rd-navbar--is-clone,
.rd-navbar.rd-navbar-sidebar + .rd-navbar.rd-navbar--is-clone {
  display: none;
}

/*
* Navbar components
*/
.rd-navbar {
  display: none;
  background: #fff;
}

.rd-navbar-toggle {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;
  line-height: 48px;
  cursor: pointer;
  color: #fff;
  background-color: transparent;
  border: none;
  color: #0e3655;
  display: none;
}

.rd-navbar-toggle span {
  position: relative;
  display: block;
  margin: auto;
  transition: .3s all ease;
}

.rd-navbar-toggle span:after, .rd-navbar-toggle span:before {
  content: "";
  position: absolute;
  left: 0;
  top: -10px;
  transition: .3s all ease;
}

.rd-navbar-toggle span:after {
  top: 10px;
}

.rd-navbar-toggle span:after, .rd-navbar-toggle span:before, .rd-navbar-toggle span {
  width: 28px;
  height: 5px;
  background-color: #fff;
  backface-visibility: hidden;
  border-radius: 2px;
}

.rd-navbar-toggle span {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rd-navbar-toggle span:before, .rd-navbar-toggle span:after {
  -webkit-transform-origin: 2px center;
  -moz-transform-origin: 2px center;
  -ms-transform-origin: 2px center;
  transform-origin: 2px center;
  -webkit-transform-origin: 2px center;
  -moz-transform-origin: 2px center;
  -ms-transform-origin: 2px center;
  transform-origin: 2px center;
}

.rd-navbar-toggle.active span {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.rd-navbar-toggle.active span:before, .rd-navbar-toggle.active span:after {
  top: 0;
  width: 17.5px;
}

.rd-navbar-toggle.active span:before {
  -webkit-transform: rotate3d(0, 0, 1, -40deg);
  transform: rotate3d(0, 0, 1, -40deg);
}

.rd-navbar-toggle.active span:after {
  -webkit-transform: rotate3d(0, 0, 1, 40deg);
  transform: rotate3d(0, 0, 1, 40deg);
}

.rd-navbar-toggle span {
  height: 4px;
  background-color: #0e3655;
}

.rd-navbar-toggle span:before, .rd-navbar-toggle span:after {
  height: 4px;
  background-color: #0e3655;
}

.rd-navbar-toggle span:before {
  top: -6px;
}

.rd-navbar-toggle span:after {
  top: 6px;
}

.rd-navbar-toggle:focus {
  outline: none;
}

.rd-navbar-brand .brand-name {
  line-height: 1;
  display: inline-block;
}

.rd-navbar-brand .brand-name span.brand-slogan {
  font-family: "Istok Web", sans-serif;
  font-size: 10px;
  color: #c1c1c1;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  font-weight: 400;
  display: block;
  padding-left: 3px;
  white-space: nowrap;
}

.rd-navbar-brand .brand-name span {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 30px;
  text-transform: uppercase;
}

.rd-navbar-brand .brand-name span:nth-of-type(2), .rd-navbar-brand .brand-name span:nth-of-type(3) {
  color: #43ca83;
}

.rd-navbar-brand .brand-name span:nth-of-type(4) {
  color: #0e3655;
}

.rd-navbar-brand .brand-name .letter {
  position: relative;
}

.rd-navbar-brand .brand-name .letter:before {
  content: '';
  position: absolute;
  display: inline-block;
  height: 5px;
  width: 8px;
  bottom: 3px;
  left: 3px;
  background-color: #43ca83;
  -webkit-transform: rotate(-59deg);
  transform: rotate(-59deg);
}

.rd-navbar-dropdown {
  display: none;
}

.rd-navbar-search {
  position: relative;
}

.rd-navbar-search .form-control::-moz-placeholder {
  color: #424343;
  opacity: 1;
}

.rd-navbar-search .form-control:-ms-input-placeholder {
  color: #424343;
}

.rd-navbar-search .form-control::-webkit-input-placeholder {
  color: #424343;
}

.rd-navbar-search-toggle {
  display: inline-block;
  font: 400 18px/36px "FontAwesome";
  vertical-align: middle;
  margin-left: 20px;
}

@media (min-width: 1600px) {
  .rd-navbar-search-toggle {
    margin-left: 55px;
  }
}

.rd-navbar-search-toggle, .rd-navbar-search-toggle:before, .rd-navbar-search-toggle:after {
  text-align: center;
  width: 36px;
  height: 36px;
}

.rd-navbar-search-toggle:hover {
  color: #ea6c56;
  cursor: pointer;
}

.rd-navbar-search-toggle:before, .rd-navbar-search-toggle:after {
  position: absolute;
  left: 0;
  top: 0;
}

.rd-navbar-search-toggle:before {
  content: '\f002';
  transition: .3s all ease;
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
}

.rd-navbar:not(.rd-navbar-fixed) .rd-navbar-search-toggle:after {
  content: '\f00d';
  transition: .3s all ease;
  -webkit-transform: scale(0) rotate(-90deg);
  transform: scale(0) rotate(-90deg);
}

.rd-navbar:not(.rd-navbar-fixed) .rd-navbar-search-toggle.active:before {
  -webkit-transform: scale(0) rotate(90deg);
  transform: scale(0) rotate(90deg);
}

.rd-navbar:not(.rd-navbar-fixed) .rd-navbar-search-toggle.active:after {
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
}

.rd-navbar-fixed .rd-navbar-search-toggle, .rd-navbar-fixed .rd-navbar-search-toggle:before {
  width: 48px;
  line-height: 48px;
}

.rd-navbar-search-form-submit {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;
  line-height: 48px;
  cursor: pointer;
  color: #424343;
  text-align: center;
  font-size: 28px;
}

.rd-navbar-search-form-submit:before {
  content: "\f002";
  font-weight: 400;
  font-family: "FontAwesome";
}

.rd-navbar-search-form-submit.active {
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
}

.rd-navbar-search .fa-search {
  border: none;
  background-color: transparent;
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 0;
}

.rd-navbar-live-search-results {
  position: absolute;
  left: 4px;
  right: 4px;
  padding: 16px 8px 8px;
  top: 100%;
  font-size: 16px;
  line-height: 34px;
  color: #333;
  background: #fff;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.14);
  opacity: 0;
  visibility: hidden;
  text-align: left;
  z-index: 998;
}

.rd-navbar-live-search-results .search-quick-result {
  padding-left: 8px;
  font-size: 14px;
  line-height: 30px;
  color: #757575;
}

.rd-navbar-live-search-results .search_list {
  margin-top: 4px;
  font-size: 16px;
  line-height: 30px;
}

.rd-navbar-live-search-results .search_list li + li {
  margin-top: 2px;
}

.rd-navbar-live-search-results .search_list .search_list li + li:last-child {
  margin-top: 8px;
  border-top: 1px solid #EBEBEB;
  padding-top: 7px;
}

.rd-navbar-live-search-results .search_link {
  display: block;
  padding: 8px;
  color: #757575;
  border-radius: 2px;
}

.rd-navbar-live-search-results .search_link:hover {
  background: #F7F7F7;
}

.rd-navbar-live-search-results .search_link p {
  margin-top: 0;
  font-size: 14px;
  display: none;
}

.rd-navbar-live-search-results .search_title {
  color: #212121;
  font-weight: 400;
}

.rd-navbar-live-search-results .search_submit {
  display: block;
  text-align: center;
  padding: 8px;
  font-weight: 700;
  color: #ea6c56;
  text-transform: uppercase;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.rd-navbar-live-search-results .search_submit:hover {
  background: #F7F7F7;
}

.rd-navbar-live-search-results.active {
  visibility: visible;
  opacity: 1;
}

@media (min-width: 1200px) {
  .rd-navbar-live-search-results .search_link p {
    display: block;
  }
}

.rd-navbar-live-search-results {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.rd-navbar-live-search-results.active {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.rd-navbar-shop {
  display: inline-block;
  font-size: 22px;
  color: #fff;
  position: absolute;
  right: 12px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 50%;
  z-index: 9;
  transition: .3s;
}

.rd-navbar-shop:hover {
  color: #ea6c56;
}

.rd-navbar-shop:focus {
  outline: 0;
  color: #ea6c56;
}

.top-panel {
  padding-left: 5px;
  padding-right: 5px;
  background-color: #0e3655;
}

.top-panel .list-inline {
  float: left;
}

.top-panel .list-inline li {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.top-panel .list-inline li:last-of-type {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.top-panel .contact-info {
  float: right;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  height: 50px;
  vertical-align: middle;
}

.top-panel .contact-info span {
  display: inline-block;
  vertical-align: middle;
}

.top-panel .contact-info span + a {
  margin-left: 10px;
}

.top-panel .contact-info a {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
}

.top-panel .contact-info a:hover {
  color: #43ca83;
}

.top-panel .contact-info .address {
  margin-left: 60px;
}

.top-panel .contact-info .mail {
  margin-left: 60px;
}

@media (min-width: 1400px) {
  .top-panel {
    padding-left: 75px;
    padding-right: 75px;
  }
}

#rd-search-results-live {
  position: absolute;
  top: 107%;
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
  box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.15);
  text-align: left;
}

#rd-search-results-live #search-results {
  background: #fff;
  border-top: 1px solid #cdcdcd;
  padding: 20px;
  opacity: 0;
  visibility: hidden;
  transition: 0.33s all ease-in;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

#rd-search-results-live #search-results h5 + p {
  margin-top: 0;
}

#rd-search-results-live #search-results.active {
  visibility: visible;
  opacity: 1;
}

#rd-search-results-live #search-results .result-item {
  padding-left: 15px;
}

#rd-search-results-live #search-results p.match {
  font-size: 14px;
  letter-spacing: 0;
}

#rd-search-results-live #search-results p.match em {
  display: block;
}

#rd-search-results-live #search-results .result-item + .result-item {
  margin-top: 20px;
}

#rd-search-results-live #search-results .search_all {
  margin-top: 20px;
}

#rd-search-results-live #search-results .search_all a:hover {
  color: #43ca83;
}

.rd-navbar-live-search-results {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.rd-navbar-live-search-results.active {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

/*
* @subsection   Hybrid  Styles
*/
.rd-navbar-static .rd-navbar-search input, .rd-navbar-sidebar .rd-navbar-search input, .rd-navbar-fullwidth .rd-navbar-search input {
  width: 300px;
  padding: 0 30px 0 10px;
  font-size: 16px;
  line-height: 2;
}

.rd-navbar-static .rd-navbar-search .form-label, .rd-navbar-sidebar .rd-navbar-search .form-label, .rd-navbar-fullwidth .rd-navbar-search .form-label {
  position: absolute;
  top: 26px;
  left: 11px;
}

.rd-navbar-static:after,
.rd-navbar-fullwidth:after {
  content: '';
  background: #fff;
}

.rd-navbar-static .rd-navbar-brand,
.rd-navbar-static .rd-navbar-nav > li > a,
.rd-navbar-static .rd-navbar-search-toggle,
.rd-navbar-fullwidth .rd-navbar-brand,
.rd-navbar-fullwidth .rd-navbar-nav > li > a,
.rd-navbar-fullwidth .rd-navbar-search-toggle {
  position: relative;
  z-index: 2;
}

.rd-navbar-static .rd-navbar-nav > li > a,
.rd-navbar-fullwidth .rd-navbar-nav > li > a {
  position: relative;
  display: inline-block;
  padding: 0;
  color: #424343;
  background: transparent;
  line-height: 24px;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  transition: .3s;
  letter-spacing: 0.025em;
}

@media (min-width: 992px) {
  .rd-navbar-static .rd-navbar-nav > li > a,
  .rd-navbar-fullwidth .rd-navbar-nav > li > a {
    font-size: 14px;
  }
}

.rd-navbar-static .rd-navbar-nav > li.focus > a,
.rd-navbar-static .rd-navbar-nav > li > a:hover,
.rd-navbar-fullwidth .rd-navbar-nav > li.focus > a,
.rd-navbar-fullwidth .rd-navbar-nav > li > a:hover {
  color: #ea6c56;
  background: transparent;
}

.rd-navbar-static .rd-navbar-nav > li.active > a,
.rd-navbar-fullwidth .rd-navbar-nav > li.active > a {
  color: #ea6c56;
  background: transparent;
}

.rd-navbar-static .rd-navbar-nav > li.active .rd-navbar-submenu-toggle,
.rd-navbar-fullwidth .rd-navbar-nav > li.active .rd-navbar-submenu-toggle {
  color: #ea6c56;
}

.rd-navbar-static .rd-navbar-nav > .rd-navbar-submenu > ul,
.rd-navbar-fullwidth .rd-navbar-nav > .rd-navbar-submenu > ul {
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.14);
}

.rd-navbar-static .rd-navbar-nav > .rd-navbar-submenu.rd-navbar--has-dropdown,
.rd-navbar-fullwidth .rd-navbar-nav > .rd-navbar-submenu.rd-navbar--has-dropdown {
  position: relative;
}

.rd-navbar-static .rd-navbar-nav > .rd-navbar-submenu.rd-navbar--has-dropdown .rd-navbar-submenu-toggle,
.rd-navbar-fullwidth .rd-navbar-nav > .rd-navbar-submenu.rd-navbar--has-dropdown .rd-navbar-submenu-toggle {
  display: inline-block;
  color: #424343;
  top: -2px;
  position: relative;
}

.rd-navbar-static .rd-navbar-nav > .rd-navbar-submenu.rd-navbar--has-dropdown .rd-navbar-submenu-toggle:before,
.rd-navbar-fullwidth .rd-navbar-nav > .rd-navbar-submenu.rd-navbar--has-dropdown .rd-navbar-submenu-toggle:before {
  font-size: 10px;
  content: '\f078';
  font-family: "FontAwesome";
}

.rd-navbar-static .rd-navbar-nav > .rd-navbar-submenu.rd-navbar--has-dropdown .rd-navbar-submenu-toggle:hover,
.rd-navbar-fullwidth .rd-navbar-nav > .rd-navbar-submenu.rd-navbar--has-dropdown .rd-navbar-submenu-toggle:hover {
  color: #ea6c56;
  cursor: pointer;
}

.rd-navbar-static .rd-navbar-nav > .rd-navbar-submenu.rd-navbar--has-dropdown:hover .rd-navbar-submenu-toggle, .rd-navbar-static .rd-navbar-nav > .rd-navbar-submenu.rd-navbar--has-dropdown.focus .rd-navbar-submenu-toggle, .rd-navbar-static .rd-navbar-nav > .rd-navbar-submenu.rd-navbar--has-dropdown.active .rd-navbar-submenu-toggle,
.rd-navbar-fullwidth .rd-navbar-nav > .rd-navbar-submenu.rd-navbar--has-dropdown:hover .rd-navbar-submenu-toggle,
.rd-navbar-fullwidth .rd-navbar-nav > .rd-navbar-submenu.rd-navbar--has-dropdown.focus .rd-navbar-submenu-toggle,
.rd-navbar-fullwidth .rd-navbar-nav > .rd-navbar-submenu.rd-navbar--has-dropdown.active .rd-navbar-submenu-toggle {
  color: #ea6c56;
}

.rd-navbar-static .rd-navbar-nav > .rd-navbar-submenu.rd-navbar--has-megamenu > a,
.rd-navbar-fullwidth .rd-navbar-nav > .rd-navbar-submenu.rd-navbar--has-megamenu > a {
  position: relative;
}

.rd-navbar-static .rd-navbar-nav > .rd-navbar-submenu.rd-navbar--has-megamenu > a:after,
.rd-navbar-fullwidth .rd-navbar-nav > .rd-navbar-submenu.rd-navbar--has-megamenu > a:after {
  font-size: 10px;
  content: '\f078';
  font-family: "FontAwesome";
  margin-left: 5px;
  vertical-align: top;
}

.rd-navbar-static .rd-navbar-nav > .rd-navbar-submenu > .rd-navbar-dropdown,
.rd-navbar-static .rd-navbar-nav > .rd-navbar-submenu > .rd-navbar-megamenu,
.rd-navbar-fullwidth .rd-navbar-nav > .rd-navbar-submenu > .rd-navbar-dropdown,
.rd-navbar-fullwidth .rd-navbar-nav > .rd-navbar-submenu > .rd-navbar-megamenu {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(30px) translateX(-50%);
  transform: translateY(30px) translateX(-50%);
}

@media (min-width: 1800px) {
  .rd-navbar-static .rd-navbar-nav > .rd-navbar-submenu > .rd-navbar-dropdown,
  .rd-navbar-static .rd-navbar-nav > .rd-navbar-submenu > .rd-navbar-megamenu,
  .rd-navbar-fullwidth .rd-navbar-nav > .rd-navbar-submenu > .rd-navbar-dropdown,
  .rd-navbar-fullwidth .rd-navbar-nav > .rd-navbar-submenu > .rd-navbar-megamenu {
    -webkit-transform: translateY(30px) translateX(0);
    transform: translateY(30px) translateX(0);
  }
}

.rd-navbar-static .rd-navbar-nav > .rd-navbar-submenu > .rd-navbar-dropdown a,
.rd-navbar-static .rd-navbar-nav > .rd-navbar-submenu > .rd-navbar-megamenu a,
.rd-navbar-fullwidth .rd-navbar-nav > .rd-navbar-submenu > .rd-navbar-dropdown a,
.rd-navbar-fullwidth .rd-navbar-nav > .rd-navbar-submenu > .rd-navbar-megamenu a {
  font-family: "Istok Web", sans-serif;
}

.rd-navbar-static .rd-navbar-nav > .rd-navbar-submenu.focus > .rd-navbar-dropdown,
.rd-navbar-static .rd-navbar-nav > .rd-navbar-submenu.focus > .rd-navbar-megamenu,
.rd-navbar-fullwidth .rd-navbar-nav > .rd-navbar-submenu.focus > .rd-navbar-dropdown,
.rd-navbar-fullwidth .rd-navbar-nav > .rd-navbar-submenu.focus > .rd-navbar-megamenu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0) translateX(-50%);
  transform: translateY(0) translateX(-50%);
}

@media (min-width: 1800px) {
  .rd-navbar-static .rd-navbar-nav > .rd-navbar-submenu.focus > .rd-navbar-dropdown,
  .rd-navbar-static .rd-navbar-nav > .rd-navbar-submenu.focus > .rd-navbar-megamenu,
  .rd-navbar-fullwidth .rd-navbar-nav > .rd-navbar-submenu.focus > .rd-navbar-dropdown,
  .rd-navbar-fullwidth .rd-navbar-nav > .rd-navbar-submenu.focus > .rd-navbar-megamenu {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
}

.rd-navbar-static .rd-navbar-nav > .rd-navbar-submenu .rd-navbar-submenu.focus > .rd-navbar-dropdown,
.rd-navbar-fullwidth .rd-navbar-nav > .rd-navbar-submenu .rd-navbar-submenu.focus > .rd-navbar-dropdown {
  display: block;
}

.rd-navbar-static .rd-navbar-inner,
.rd-navbar-fullwidth .rd-navbar-inner {
  position: relative;
  max-width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 40px;
  padding-bottom: 35px;
}

@media (min-width: 1400px) {
  .rd-navbar-static .rd-navbar-inner,
  .rd-navbar-fullwidth .rd-navbar-inner {
    padding-left: 75px;
    padding-right: 75px;
  }
}

.rd-navbar-static .rd-navbar-wrap,
.rd-navbar-fullwidth .rd-navbar-wrap {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.rd-navbar-static .rd-navbar-nav,
.rd-navbar-fullwidth .rd-navbar-nav {
  display: inline-block;
}

.rd-navbar-static .rd-navbar-nav > li > .rd-navbar-dropdown,
.rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-dropdown {
  display: block;
  position: absolute;
  text-align: left;
  margin-top: 10px;
  left: -20px;
  width: 270px;
  background: #fff;
  z-index: 5;
  border-radius: 6px;
  padding: 0 10px;
}

@media (min-width: 768px) {
  .rd-navbar-static .rd-navbar-nav > li:nth-of-type(7) > .rd-navbar-dropdown,
  .rd-navbar-fullwidth .rd-navbar-nav > li:nth-of-type(7) > .rd-navbar-dropdown {
    left: -64px;
  }
}

@media (min-width: 992px) {
  .rd-navbar-static .rd-navbar-nav > li:nth-of-type(7) > .rd-navbar-dropdown,
  .rd-navbar-fullwidth .rd-navbar-nav > li:nth-of-type(7) > .rd-navbar-dropdown {
    left: -34px;
  }
}

@media (min-width: 1200px) {
  .rd-navbar-static .rd-navbar-nav > li:nth-of-type(7) > .rd-navbar-dropdown,
  .rd-navbar-fullwidth .rd-navbar-nav > li:nth-of-type(7) > .rd-navbar-dropdown {
    left: -20px;
  }
}

.rd-navbar-static .rd-navbar-nav li.rd-navbar--has-dropdown,
.rd-navbar-fullwidth .rd-navbar-nav li.rd-navbar--has-dropdown {
  position: relative;
}

.rd-navbar-static .rd-navbar-nav li.focus > .rd-navbar-dropdown, .rd-navbar-static .rd-navbar-nav li.focus > .rd-navbar-megamenu,
.rd-navbar-static .rd-navbar-nav li.opened > .rd-navbar-dropdown,
.rd-navbar-static .rd-navbar-nav li.opened > .rd-navbar-megamenu,
.rd-navbar-fullwidth .rd-navbar-nav li.focus > .rd-navbar-dropdown,
.rd-navbar-fullwidth .rd-navbar-nav li.focus > .rd-navbar-megamenu,
.rd-navbar-fullwidth .rd-navbar-nav li.opened > .rd-navbar-dropdown,
.rd-navbar-fullwidth .rd-navbar-nav li.opened > .rd-navbar-megamenu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.rd-navbar-static .rd-navbar-nav > li,
.rd-navbar-fullwidth .rd-navbar-nav > li {
  display: inline-block;
}

.rd-navbar-static .rd-navbar-nav > li > .rd-navbar-dropdown,
.rd-navbar-static .rd-navbar-nav > li > .rd-navbar-megamenu,
.rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-dropdown,
.rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-megamenu {
  top: 100%;
  z-index: 15;
}

.rd-navbar-static .rd-navbar-nav > li > .rd-navbar-dropdown .rd-navbar-dropdown,
.rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-dropdown .rd-navbar-dropdown {
  left: 100%;
  top: 0;
  z-index: 2;
}

.rd-navbar-static .rd-navbar-dropdown,
.rd-navbar-fullwidth .rd-navbar-dropdown {
  background: #fff;
}

.rd-navbar-static .rd-navbar-dropdown .rd-navbar-dropdown > li > a,
.rd-navbar-fullwidth .rd-navbar-dropdown .rd-navbar-dropdown > li > a {
  color: #fff;
}

.rd-navbar-static .rd-navbar-dropdown li + li a,
.rd-navbar-fullwidth .rd-navbar-dropdown li + li a {
  border-top: 1px solid #e4e4e4;
}

.rd-navbar-static .rd-navbar-dropdown > li > a,
.rd-navbar-fullwidth .rd-navbar-dropdown > li > a {
  font-size: 14px;
  display: block;
  padding: 10px 13px;
  color: #424343;
  background: transparent;
  position: relative;
  transition: .3s;
}

.rd-navbar-static .rd-navbar-dropdown > li > a:before,
.rd-navbar-fullwidth .rd-navbar-dropdown > li > a:before {
  content: '\f105';
  position: absolute;
  display: inline-block;
  font-size: 14px;
  font-family: "FontAwesome";
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  color: #c1c1c1;
  transition: .3s;
  opacity: 0;
}

.rd-navbar-static .rd-navbar-dropdown > li > a:hover,
.rd-navbar-fullwidth .rd-navbar-dropdown > li > a:hover {
  color: #ea6c56;
  background: transparent;
  padding-left: 30px;
}

.rd-navbar-static .rd-navbar-dropdown > li > a:hover:before,
.rd-navbar-fullwidth .rd-navbar-dropdown > li > a:hover:before {
  opacity: 1;
  left: 15px;
}

.rd-navbar-static .rd-navbar-dropdown > li.focus > a,
.rd-navbar-fullwidth .rd-navbar-dropdown > li.focus > a {
  color: #ea6c56;
  background: transparent;
}

.rd-navbar-static .rd-navbar-megamenu,
.rd-navbar-fullwidth .rd-navbar-megamenu {
  display: block;
  width: 98%;
  position: absolute;
  text-align: left;
  border-spacing: 31px 25px;
  margin-top: 10px;
  max-width: 1200px;
  background: #fff;
  z-index: 4;
  border-radius: 6px;
}

@media (min-width: 992px) {
  .rd-navbar-static .rd-navbar-megamenu,
  .rd-navbar-fullwidth .rd-navbar-megamenu {
    left: 50%;
    width: 100%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

@media (min-width: 1800px) {
  .rd-navbar-static .rd-navbar-megamenu,
  .rd-navbar-fullwidth .rd-navbar-megamenu {
    left: 30px;
    width: calc(100% - 30px);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.rd-navbar-static .rd-navbar-megamenu > li,
.rd-navbar-fullwidth .rd-navbar-megamenu > li {
  display: table-cell;
  width: 25%;
}

.rd-navbar-static .rd-navbar-megamenu > li > p,
.rd-navbar-fullwidth .rd-navbar-megamenu > li > p {
  font-weight: 400;
  text-transform: uppercase;
  color: #fff;
}

.rd-navbar-static .rd-navbar-megamenu > li > ul li + li,
.rd-navbar-fullwidth .rd-navbar-megamenu > li > ul li + li {
  margin-top: 11px;
}

.rd-navbar-static .rd-navbar-megamenu > li > ul a,
.rd-navbar-fullwidth .rd-navbar-megamenu > li > ul a {
  display: inline-block;
  color: #424343;
  background: transparent;
  position: relative;
  font-size: 14px;
  line-height: 15px;
}

.rd-navbar-static .rd-navbar-megamenu > li > ul a:before,
.rd-navbar-fullwidth .rd-navbar-megamenu > li > ul a:before {
  content: '\f105';
  position: absolute;
  display: inline-block;
  font-size: 14px;
  font-family: "FontAwesome";
  top: 0;
  left: -18px;
  color: #c1c1c1;
  transition: .3s;
  opacity: 0;
}

.rd-navbar-static .rd-navbar-megamenu > li > ul a:hover,
.rd-navbar-fullwidth .rd-navbar-megamenu > li > ul a:hover {
  color: #ea6c56;
  background: transparent;
  margin-left: 18px;
}

.rd-navbar-static .rd-navbar-megamenu > li > ul a:hover:before,
.rd-navbar-fullwidth .rd-navbar-megamenu > li > ul a:hover:before {
  opacity: 1;
}

.rd-navbar-static .rd-navbar-megamenu > li + li,
.rd-navbar-fullwidth .rd-navbar-megamenu > li + li {
  padding-left: 20px;
}

.rd-navbar-static.rd-navbar--is-clone,
.rd-navbar-fullwidth.rd-navbar--is-clone {
  display: none;
}

.rd-navbar-static.rd-navbar--is-clone.rd-navbar--is-stuck,
.rd-navbar-fullwidth.rd-navbar--is-clone.rd-navbar--is-stuck {
  display: block;
}

.rd-navbar-static .rd-navbar-search.rd-navbar-search-sm,
.rd-navbar-fullwidth .rd-navbar-search.rd-navbar-search-sm {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 300px;
  right: 10px;
  bottom: -57px;
  transition: .3s;
  z-index: 2;
}

@media (min-width: 1200px) {
  .rd-navbar-static .rd-navbar-search.rd-navbar-search-sm,
  .rd-navbar-fullwidth .rd-navbar-search.rd-navbar-search-sm {
    bottom: -93px;
    right: 0;
  }
}

.rd-navbar-static .rd-navbar-search.rd-navbar-search-sm .icon-gray-dark:hover,
.rd-navbar-fullwidth .rd-navbar-search.rd-navbar-search-sm .icon-gray-dark:hover {
  color: #4bafd2;
}

.rd-navbar-static .rd-navbar-search.rd-navbar-search-sm.active,
.rd-navbar-fullwidth .rd-navbar-search.rd-navbar-search-sm.active {
  opacity: 1;
  visibility: visible;
}

.rd-navbar-static.rd-navbar--is-stuck, .rd-navbar-static.rd-navbar--is-clone,
.rd-navbar-fullwidth.rd-navbar--is-stuck,
.rd-navbar-fullwidth.rd-navbar--is-clone {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 999;
  background: #fff;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
}

.rd-navbar-static.rd-navbar--is-stuck .top-panel, .rd-navbar-static.rd-navbar--is-clone .top-panel,
.rd-navbar-fullwidth.rd-navbar--is-stuck .top-panel,
.rd-navbar-fullwidth.rd-navbar--is-clone .top-panel {
  display: none;
}

.rd-navbar-static.rd-navbar--is-stuck .rd-navbar-inner, .rd-navbar-static.rd-navbar--is-clone .rd-navbar-inner,
.rd-navbar-fullwidth.rd-navbar--is-stuck .rd-navbar-inner,
.rd-navbar-fullwidth.rd-navbar--is-clone .rd-navbar-inner {
  padding-top: 15px;
  padding-bottom: 15px;
}

.rd-navbar-static.rd-navbar--is-stuck .rd-search, .rd-navbar-static.rd-navbar--is-clone .rd-search,
.rd-navbar-fullwidth.rd-navbar--is-stuck .rd-search,
.rd-navbar-fullwidth.rd-navbar--is-clone .rd-search {
  bottom: -76px;
}

.rd-navbar-static .rd-navbar-dropdown,
.rd-navbar-static .rd-navbar-megamenu,
.rd-navbar-fullwidth .rd-navbar-dropdown,
.rd-navbar-fullwidth .rd-navbar-megamenu {
  position: absolute;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  padding: 8px 0;
  text-align: left;
  visibility: hidden;
  opacity: 0;
}

.rd-navbar-static .rd-navbar-dropdown,
.rd-navbar-fullwidth .rd-navbar-dropdown {
  width: 270px;
}

.rd-navbar-static .rd-navbar--has-dropdown,
.rd-navbar-fullwidth .rd-navbar--has-dropdown {
  position: relative;
}

.rd-navbar-fixed .rd-navbar-collapse-toggle,
.rd-navbar-sidebar .rd-navbar-collapse-toggle {
  display: inline-block;
  z-index: 9999;
}

.rd-navbar-fixed .rd-navbar-dropdown,
.rd-navbar-sidebar .rd-navbar-dropdown {
  display: block;
}

.rd-navbar-fixed .rd-navbar-collapse-items,
.rd-navbar-sidebar .rd-navbar-collapse-items {
  position: absolute;
  width: 260px;
  padding: 25px 15px;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.14);
  color: #424343;
  background: #fff;
  font-size: 16px;
  line-height: 34px;
}

.rd-navbar-fixed .rd-navbar-collapse-items li > *,
.rd-navbar-sidebar .rd-navbar-collapse-items li > * {
  vertical-align: middle;
}

.rd-navbar-fixed .rd-navbar-collapse-items li + li,
.rd-navbar-sidebar .rd-navbar-collapse-items li + li {
  margin-top: 10px;
}

.rd-navbar-fixed .rd-navbar-collapse-items .icon,
.rd-navbar-fixed .rd-navbar-collapse-items a,
.rd-navbar-sidebar .rd-navbar-collapse-items .icon,
.rd-navbar-sidebar .rd-navbar-collapse-items a {
  display: inline-block;
  font-size: 16px;
  line-height: 30px;
}

.rd-navbar-fixed .rd-navbar-collapse-items .icon, .rd-navbar-fixed .rd-navbar-collapse-items a[class*="fa"]:before,
.rd-navbar-sidebar .rd-navbar-collapse-items .icon,
.rd-navbar-sidebar .rd-navbar-collapse-items a[class*="fa"]:before {
  display: inline-block;
  width: 30px;
  height: 30px;
  padding-right: 5px;
}

.rd-navbar-fixed .rd-navbar-nav,
.rd-navbar-sidebar {
  width: 270px;
  height: 100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  font-size: 16px;
  line-height: 34px;
  color: #424343;
  background: #fff;
  z-index: 998;
  padding: 10px 0;
}

.rd-navbar-fixed .rd-navbar-nav:before, .rd-navbar-fixed .rd-navbar-nav:after,
.rd-navbar-sidebar:before,
.rd-navbar-sidebar:after {
  content: '';
  display: block;
  height: 56px;
}

.rd-navbar-fixed .rd-navbar-nav::-webkit-scrollbar,
.rd-navbar-sidebar::-webkit-scrollbar {
  width: 4px;
}

.rd-navbar-fixed .rd-navbar-nav::-webkit-scrollbar-thumb,
.rd-navbar-sidebar::-webkit-scrollbar-thumb {
  background: white;
  border: none;
  border-radius: 0;
  opacity: .2;
}

.rd-navbar-fixed .rd-navbar-nav::-webkit-scrollbar-track,
.rd-navbar-sidebar::-webkit-scrollbar-track {
  background: #fff;
  border: none;
  border-radius: 0;
}

.rd-navbar-fixed .rd-navbar-nav li,
.rd-navbar-sidebar .rd-navbar-nav li {
  position: relative;
}

.rd-navbar-fixed .rd-navbar-nav li li > a,
.rd-navbar-sidebar .rd-navbar-nav li li > a {
  padding-left: 20px;
}

.rd-navbar-fixed .rd-navbar-nav li > a,
.rd-navbar-sidebar .rd-navbar-nav li > a {
  position: relative;
  display: block;
  padding: 5px 15px;
}

.rd-navbar-fixed .rd-navbar-nav li > a:first-letter,
.rd-navbar-sidebar .rd-navbar-nav li > a:first-letter {
  text-transform: uppercase;
}

.rd-navbar-fixed .rd-navbar-nav li.active > a,
.rd-navbar-sidebar .rd-navbar-nav li.active > a {
  color: #e4e4e4;
  background: #f2f2f2;
}

.rd-navbar-fixed .rd-navbar-nav li.focus > a, .rd-navbar-fixed .rd-navbar-nav li > a:hover,
.rd-navbar-sidebar .rd-navbar-nav li.focus > a,
.rd-navbar-sidebar .rd-navbar-nav li > a:hover {
  color: #ea6c56;
  background: transparent;
}

.rd-navbar-fixed .rd-navbar-nav li + li, .rd-navbar-fixed .rd-navbar-nav li > img + a, .rd-navbar-fixed .rd-navbar-nav li > a + a, .rd-navbar-fixed .rd-navbar-nav li > a + ul,
.rd-navbar-sidebar .rd-navbar-nav li + li,
.rd-navbar-sidebar .rd-navbar-nav li > img + a,
.rd-navbar-sidebar .rd-navbar-nav li > a + a,
.rd-navbar-sidebar .rd-navbar-nav li > a + ul {
  margin-top: 4px;
}

.rd-navbar-fixed .rd-navbar-nav li + .li-only-fixed,
.rd-navbar-sidebar .rd-navbar-nav li + .li-only-fixed {
  margin-top: 20px;
}

.rd-navbar-fixed .rd-navbar-nav .rd-navbar-dropdown > li > a,
.rd-navbar-sidebar .rd-navbar-nav .rd-navbar-dropdown > li > a {
  padding-left: 20px;
}

.rd-navbar-fixed .rd-navbar-nav .rd-navbar-dropdown ul > li > a,
.rd-navbar-sidebar .rd-navbar-nav .rd-navbar-dropdown ul > li > a {
  padding-left: 25px;
}

.rd-navbar-fixed .rd-navbar-search-form-submit,
.rd-navbar-sidebar .rd-navbar-search-form-submit {
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
}

/*
* Static Layout
*/
.rd-navbar-static {
  display: block;
}

.rd-navbar-static .rd-navbar-nav-wrap {
  width: 70%;
  text-align: center;
}

@media (min-width: 1800px) {
  .rd-navbar-static .rd-navbar-nav-wrap {
    width: 100%;
    max-width: 1200px;
    padding: 0 30px;
    text-align: left;
    position: relative;
  }
}

@media (min-width: 1800px) {
  .rd-navbar-static .rd-navbar-nav {
    position: static;
  }
}

.rd-navbar-static .rd-navbar-nav > li {
  display: inline-block;
}

.rd-navbar-static .rd-navbar-nav > li + li {
  margin-left: 16px;
}

@media (min-width: 1600px) {
  .rd-navbar-static .rd-navbar-nav > li + li {
    margin-left: 40px;
  }
}

@media (min-width: 1800px) {
  .rd-navbar-static .rd-navbar-nav > li + li {
    margin-left: 55px;
  }
}

.rd-navbar-static .top-panel-toggle {
  display: none;
}

/*
* Fullwidth Layout
*/
.rd-navbar-fullwidth {
  display: block;
}

.rd-navbar-fullwidth .rd-navbar-nav > li + li {
  margin-left: 16px;
}

.rd-navbar-fullwidth.rd-navbar--is-stuck .rd-navbar-panel {
  display: none;
}

.rd-navbar-fullwidth.rd-navbar--is-stuck .btn {
  display: none;
}

.rd-navbar-fullwidth.rd-navbar--is-stuck .rd-navbar-nav-wrap {
  margin-top: 0;
}

.rd-navbar-fullwidth .top-panel {
  padding: 0 5px;
}

.rd-navbar-fullwidth .top-panel .list-inline li {
  border: none;
}

.rd-navbar-fullwidth .top-panel .list-inline li a {
  font-size: 15px;
  width: 23px;
  height: 38px;
  line-height: 2.5;
}

.rd-navbar-fullwidth .top-panel .contact-info {
  height: 38px;
  font-size: 12px;
}

.rd-navbar-fullwidth .top-panel .contact-info .address, .rd-navbar-fullwidth .top-panel .contact-info .mail {
  margin-left: 16px;
}

.rd-navbar-fullwidth .rd-navbar-wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.rd-navbar-fullwidth .rd-navbar-inner {
  padding: 20px 5px;
}

.rd-navbar-fullwidth .rd-navbar-panel {
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}

.rd-navbar-fullwidth .rd-navbar-nav-wrap {
  -webkit-order: 3;
  -ms-flex-order: 3;
  order: 3;
  margin-top: 10px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.rd-navbar-fullwidth .rd-navbar-nav {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.rd-navbar-fullwidth .btn {
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
}

.rd-navbar-fullwidth .rd-navbar-search-toggle {
  margin-left: 20px;
}

@media (min-width: 992px) {
  .rd-navbar-fullwidth .top-panel {
    padding: 0 10px;
  }
  .rd-navbar-fullwidth .top-panel .list-inline li a {
    font-size: 18px;
    width: 38px;
    height: 38px;
    line-height: 38px;
  }
  .rd-navbar-fullwidth .top-panel .contact-info {
    font-size: 15px;
  }
  .rd-navbar-fullwidth .rd-navbar-inner {
    padding: 20px 15px;
  }
  .rd-navbar-fullwidth .rd-navbar-search-toggle {
    margin-left: 20px;
  }
}

/*
* Fixed Layout
*/
.rd-navbar-fixed {
  display: block;
}

.rd-navbar-fixed .rd-navbar-search-sm {
  display: none;
}

.rd-navbar-fixed .li-only-fixed {
  padding-left: 16px;
}

.rd-navbar-fixed .li-only-fixed .telephone-fixed, .rd-navbar-fixed .li-only-fixed .mail-fixed, .rd-navbar-fixed .li-only-fixed .address-fixed {
  display: block;
  text-align: left;
}

.rd-navbar-fixed .li-only-fixed .telephone-fixed a, .rd-navbar-fixed .li-only-fixed .mail-fixed a, .rd-navbar-fixed .li-only-fixed .address-fixed a {
  color: #7f7f7f;
}

.rd-navbar-fixed .li-only-fixed .telephone-fixed a:hover, .rd-navbar-fixed .li-only-fixed .mail-fixed a:hover, .rd-navbar-fixed .li-only-fixed .address-fixed a:hover {
  color: #43ca83;
}

.rd-navbar-fixed .li-only-fixed .telephone-fixed {
  margin-top: 40px;
}

.rd-navbar-fixed .li-only-fixed .mail-fixed {
  margin-top: 10px;
}

.rd-navbar-fixed .li-only-fixed .address-fixed {
  margin-top: 10px;
}

.rd-navbar-fixed .li-only-fixed .address-fixed {
  position: relative;
}

.rd-navbar-fixed .li-only-fixed .rd-navbar-search .form-group {
  position: static;
  visibility: visible;
  opacity: 1;
}

.rd-navbar-fixed .li-only-fixed .rd-navbar-search .form-group input {
  padding: 9px 35px 9px 20px;
  height: 43px;
  font-size: 12px;
  line-height: 2.2;
}

.rd-navbar-fixed .li-only-fixed #rd-search-results-live {
  display: none;
}

.rd-navbar-fixed .li-only-fixed .rd-search {
  max-width: 89%;
  margin-right: auto;
}

.rd-navbar-fixed .rd-navbar-brand {
  display: block;
  text-align: left;
  position: fixed;
  top: 10px;
  left: 66px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 22px;
  line-height: 46px;
  height: 38px;
  z-index: 17;
}

.rd-navbar-fixed .rd-navbar-brand .brand-name {
  color: #fff;
}

.rd-navbar-fixed .rd-navbar-brand .brand-name .brand-slogan {
  display: none;
}

.rd-navbar-fixed .rd-navbar-panel {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  padding: 4px;
  height: 56px;
  color: #424343;
  z-index: 999;
}

.rd-navbar-fixed .rd-navbar-panel:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  box-shadow: 0 0 13px -1px rgba(0, 0, 0, 0.17);
  background: #fff;
}

.rd-navbar-fixed .rd-navbar-toggle {
  display: inline-block;
}

.rd-navbar-fixed .rd-navbar-nav-wrap {
  position: fixed;
  top: -56px;
  left: 0;
  width: 273px;
  padding: 112px 0 56px;
  bottom: -56px;
  color: #fff;
  background: #fff;
  z-index: 15;
  -webkit-transform: translateX(-120%);
  transform: translateX(-120%);
  box-shadow: 0 0 11px 2px rgba(0, 0, 0, 0.17);
}

.rd-navbar-fixed .rd-navbar-nav-wrap.active {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.rd-navbar-fixed .rd-navbar-nav {
  display: block;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 16px;
  line-height: 26px;
  text-align: left;
}

.rd-navbar-fixed .rd-navbar-nav li a {
  font-size: 14px;
}

.rd-navbar-fixed .rd-navbar-nav > li:last-of-type a {
  font-size: 16px;
}

.rd-navbar-fixed .rd-navbar-nav:before, .rd-navbar-fixed .rd-navbar-nav:after {
  content: '';
  display: block;
  height: 8px;
}

.rd-navbar-fixed .rd-navbar-nav > li > a {
  display: block;
  padding: 11px 56px 11px 16px;
  color: #424343;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.rd-navbar-fixed .rd-navbar-nav > li.opened > a, .rd-navbar-fixed .rd-navbar-nav > li a:hover {
  color: #ea6c56;
}

.rd-navbar-fixed .rd-navbar-nav > li.active .rd-navbar-submenu-toggle:after {
  color: #424343;
}

.rd-navbar-fixed .rd-navbar-nav > li.active > a {
  color: #ea6c56;
  background: transparent;
}

.rd-navbar-fixed .rd-navbar-dropdown,
.rd-navbar-fixed .rd-navbar-megamenu {
  display: none;
}

.rd-navbar-fixed .rd-navbar-submenu {
  position: relative;
}

.rd-navbar-fixed .rd-navbar-submenu li > a {
  padding-left: 32px;
}

.rd-navbar-fixed .rd-navbar-submenu .rd-navbar-dropdown li li > a,
.rd-navbar-fixed .rd-navbar-submenu .rd-navbar-megamenu ul li li > a {
  padding-left: 48px;
}

.rd-navbar-fixed .rd-navbar-submenu.opened > .rd-navbar-dropdown,
.rd-navbar-fixed .rd-navbar-submenu.opened > .rd-navbar-megamenu {
  display: block;
}

.rd-navbar-fixed .rd-navbar-nav li:hover > a, .rd-navbar-fixed .rd-navbar-nav li:hover > a:hover, .rd-navbar-fixed .rd-navbar-nav li.focus > a, .rd-navbar-fixed .rd-navbar-nav li.focus > a:hover {
  color: #ea6c56;
  background: transparent;
}

.rd-navbar-fixed .rd-navbar-nav li:hover > .rd-navbar-submenu-toggle, .rd-navbar-fixed .rd-navbar-nav li.focus > .rd-navbar-submenu-toggle {
  color: #fff;
}

.rd-navbar-fixed .rd-navbar-nav li:hover > .rd-navbar-submenu-toggle:hover, .rd-navbar-fixed .rd-navbar-nav li.focus > .rd-navbar-submenu-toggle:hover {
  cursor: pointer;
  color: #fff;
}

.rd-navbar-fixed .rd-navbar-nav li .rd-navbar-dropdown,
.rd-navbar-fixed .rd-navbar-nav li .rd-navbar-megamenu {
  transition: opacity 0.3s, height 0.4s ease;
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.rd-navbar-fixed .rd-navbar-nav li .rd-navbar-dropdown > li,
.rd-navbar-fixed .rd-navbar-nav li .rd-navbar-megamenu > li {
  padding-left: 30px;
}

.rd-navbar-fixed .rd-navbar-nav li .rd-navbar-dropdown a,
.rd-navbar-fixed .rd-navbar-nav li .rd-navbar-megamenu a {
  color: #424343;
}

.rd-navbar-fixed .rd-navbar-nav li .rd-navbar-dropdown a:hover,
.rd-navbar-fixed .rd-navbar-nav li .rd-navbar-megamenu a:hover {
  color: #ea6c56;
}

.rd-navbar-fixed .rd-navbar-nav li .rd-navbar-megamenu > li {
  padding-left: 30px;
}

.rd-navbar-fixed .rd-navbar-nav li.opened > .rd-navbar-dropdown,
.rd-navbar-fixed .rd-navbar-nav li.opened > .rd-navbar-megamenu {
  opacity: 1;
  height: auto;
}

.rd-navbar-fixed .rd-navbar-nav li.opened > a {
  background: transparent;
  color: #ea6c56;
}

.rd-navbar-fixed .rd-navbar-nav li.opened > .rd-navbar-submenu-toggle {
  color: #fff;
}

.rd-navbar-fixed .rd-navbar-nav li.opened > .rd-navbar-submenu-toggle::after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rd-navbar-fixed .rd-navbar-nav .rd-navbar-submenu-toggle::after {
  content: '\f078';
  position: absolute;
  top: 22px;
  right: 0;
  margin-top: -22px;
  width: 36px;
  height: 44px;
  font: 400 10px "FontAwesome";
  line-height: 42px;
  text-align: center;
  transition: 0.4s all ease;
  z-index: 2;
  color: #424343;
}

.rd-navbar-fixed .rd-navbar-collapse,
.rd-navbar-fixed .rd-navbar-search-toggle {
  position: fixed;
  top: 4px;
  height: 48px;
  z-index: 1000;
  background-color: transparent;
  border: none;
}

.rd-navbar-fixed .rd-navbar-collapse:focus,
.rd-navbar-fixed .rd-navbar-search-toggle:focus {
  outline: none;
}

.rd-navbar-fixed.active .rd-navbar-nav {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

@media (max-width: 1199px) {
  .rd-navbar-fixed.rd-navbar--on-search .brand-name {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
}

.rd-navbar-fixed.rd-navbar--is-clone {
  display: none;
}

.rd-navbar-fixed.rd-navbar--is-clone .rd-navbar-panel {
  -webkit-transform: translateY(-110%);
  transform: translateY(-110%);
}

.rd-navbar-fixed.rd-navbar--is-clone.rd-navbar--is-stuck .rd-navbar-panel {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.rd-navbar-fixed .rd-navbar-fixed--visible {
  display: block;
}

.rd-navbar-fixed .rd-navbar-fixed--hidden {
  display: none;
}

.rd-navbar-fixed .top-panel {
  display: none;
}

.rd-navbar-fixed .btn {
  position: fixed;
  top: 8px;
  right: 3px;
  z-index: 999;
}

.rd-navbar-fixed .btn .icon {
  padding-right: 0;
}

.rd-navbar-fixed .btn span:nth-of-type(2) {
  display: none;
}

html.rd-navbar-fixed-linked body {
  padding-top: 55px;
}

@media (min-width: 768px) {
  html.rd-navbar-fixed-linked .body_mod_1 {
    margin-top: -55px;
  }
}

html.rd-navbar-fixed-linked .tablet .body_mod_1, html.rd-navbar-fixed-linked .mobile .body_mod_1 {
  margin-top: 0;
}

.rd-navbar-static .rd-navbar-nav .li-only-fixed, .rd-navbar-fullwidth .rd-navbar-nav .li-only-fixed {
  display: none;
}

.header-transparent .rd-navbar-fixed .li-only-fixed .mail-fixed, .header-transparent .rd-navbar-fixed .li-only-fixed .telephone-fixed, .header-transparent .rd-navbar-fixed .li-only-fixed .address-fixed, .header-minimal .rd-navbar-fixed .li-only-fixed .mail-fixed, .header-minimal .rd-navbar-fixed .li-only-fixed .telephone-fixed, .header-minimal .rd-navbar-fixed .li-only-fixed .address-fixed {
  display: none;
}

.header-center .rd-navbar-fixed .li-only-fixed .mail-fixed {
  display: none;
}

/*
* Sidebar Layout
*/
html.rd-navbar-sidebar-linked body {
  padding-left: 270px;
}

.rd-navbar-sidebar {
  position: fixed;
  display: block;
}

.rd-navbar-sidebar .rd-navbar-nav li:hover > a, .rd-navbar-sidebar .rd-navbar-nav li:hover > a:hover, .rd-navbar-sidebar .rd-navbar-nav li.focus > a, .rd-navbar-sidebar .rd-navbar-nav li.focus > a:hover {
  color: #ea6c56;
  background: transparent;
}

.rd-navbar-sidebar .rd-navbar-nav li:hover > .rd-navbar-submenu-toggle, .rd-navbar-sidebar .rd-navbar-nav li.focus > .rd-navbar-submenu-toggle {
  color: #fff;
}

.rd-navbar-sidebar .rd-navbar-nav li:hover > .rd-navbar-submenu-toggle:hover, .rd-navbar-sidebar .rd-navbar-nav li.focus > .rd-navbar-submenu-toggle:hover {
  cursor: pointer;
  color: #fff;
}

.rd-navbar-sidebar .rd-navbar-nav li .rd-navbar-dropdown,
.rd-navbar-sidebar .rd-navbar-nav li .rd-navbar-megamenu {
  transition: opacity 0.3s, height 0.4s ease;
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.rd-navbar-sidebar .rd-navbar-nav li.opened > .rd-navbar-dropdown,
.rd-navbar-sidebar .rd-navbar-nav li.opened > .rd-navbar-megamenu {
  opacity: 1;
  height: auto;
}

.rd-navbar-sidebar .rd-navbar-nav li.opened > a {
  background: transparent;
  color: #ea6c56;
}

.rd-navbar-sidebar .rd-navbar-nav li.opened > .rd-navbar-submenu-toggle {
  color: #fff;
}

.rd-navbar-sidebar .rd-navbar-nav li.opened > .rd-navbar-submenu-toggle::after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rd-navbar-sidebar .rd-navbar-submenu-toggle::after {
  content: '\f078';
  position: absolute;
  top: 22px;
  right: 0;
  margin-top: -22px;
  width: 65px;
  height: 44px;
  font: 400 14px "FontAwesome";
  line-height: 42px;
  text-align: center;
  transition: 0.4s all ease;
  z-index: 2;
}

.rd-navbar-sidebar .rd-navbar-brand {
  text-align: center;
}

.rd-navbar-sidebar .rd-navbar-collapse-items {
  top: 0;
  left: 0;
  padding-top: 45px;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  -webkit-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  opacity: 0;
  visibility: hidden;
}

.rd-navbar-sidebar .rd-navbar-collapse {
  position: absolute;
  top: 4px;
  left: 4px;
  display: inline-block;
  z-index: 1;
}

.rd-navbar-sidebar .rd-navbar-collapse.active .rd-navbar-collapse-items {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/*
* @subsection   Page boxed layout style redeclaration
*
* @description  Redefines navbar style inside boxed layout
*
* @see          ../modules/_page-layouts.scss
*/
html.boxed.rd-navbar--has-sidebar body {
  padding-left: 300px;
  padding-right: 30px;
}

html.boxed .rd-navbar--is-clone {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

ul ul, ul ol, ol ul, ol ol {
  padding-left: 0;
}

.rd-navbar-telephone {
  display: none;
}

.rd-navbar-telephone a {
  color: #424343;
}

.rd-navbar-telephone a:hover {
  color: #ea6c56;
}

.header-transparent {
  position: relative;
}

.header-transparent .top-panel {
  display: none;
}

.header-transparent .rd-navbar-static .rd-navbar-brand .brand-name span.brand-slogan, .header-transparent .rd-navbar-static .rd-navbar-brand .brand-name span:nth-of-type(2), .header-transparent .rd-navbar-static .rd-navbar-brand .brand-name span:nth-of-type(3), .header-transparent .rd-navbar-fullwidth .rd-navbar-brand .brand-name span.brand-slogan, .header-transparent .rd-navbar-fullwidth .rd-navbar-brand .brand-name span:nth-of-type(2), .header-transparent .rd-navbar-fullwidth .rd-navbar-brand .brand-name span:nth-of-type(3) {
  color: #fff;
}

.header-transparent .rd-navbar-static .rd-navbar-brand .brand-name span.brand-slogan:before, .header-transparent .rd-navbar-static .rd-navbar-brand .brand-name span:nth-of-type(2):before, .header-transparent .rd-navbar-static .rd-navbar-brand .brand-name span:nth-of-type(3):before, .header-transparent .rd-navbar-fullwidth .rd-navbar-brand .brand-name span.brand-slogan:before, .header-transparent .rd-navbar-fullwidth .rd-navbar-brand .brand-name span:nth-of-type(2):before, .header-transparent .rd-navbar-fullwidth .rd-navbar-brand .brand-name span:nth-of-type(3):before {
  background-color: #fff;
}

.header-transparent .rd-navbar-static .rd-navbar-nav > li > a, .header-transparent .rd-navbar-fullwidth .rd-navbar-nav > li > a {
  color: #fff;
}

.header-transparent .rd-navbar-static .rd-navbar-nav > li.focus > a,
.header-transparent .rd-navbar-static .rd-navbar-nav > li > a:hover, .header-transparent .rd-navbar-fullwidth .rd-navbar-nav > li.focus > a,
.header-transparent .rd-navbar-fullwidth .rd-navbar-nav > li > a:hover {
  color: #0e3655;
  background: transparent;
}

.header-transparent .rd-navbar-static .rd-navbar-nav > li.active > a, .header-transparent .rd-navbar-fullwidth .rd-navbar-nav > li.active > a {
  color: #0e3655;
  background: transparent;
}

.header-transparent .rd-navbar-static .rd-navbar-nav > li.rd-navbar-submenu.rd-navbar--has-dropdown .rd-navbar-submenu-toggle:hover, .header-transparent .rd-navbar-fullwidth .rd-navbar-nav > li.rd-navbar-submenu.rd-navbar--has-dropdown .rd-navbar-submenu-toggle:hover {
  color: #0e3655;
  cursor: pointer;
}

.header-transparent .rd-navbar-static .rd-navbar-nav > li.rd-navbar-submenu.rd-navbar--has-dropdown:hover .rd-navbar-submenu-toggle, .header-transparent .rd-navbar-static .rd-navbar-nav > li.rd-navbar-submenu.rd-navbar--has-dropdown.focus .rd-navbar-submenu-toggle, .header-transparent .rd-navbar-fullwidth .rd-navbar-nav > li.rd-navbar-submenu.rd-navbar--has-dropdown:hover .rd-navbar-submenu-toggle, .header-transparent .rd-navbar-fullwidth .rd-navbar-nav > li.rd-navbar-submenu.rd-navbar--has-dropdown.focus .rd-navbar-submenu-toggle {
  color: #0e3655;
}

@media (min-width: 768px) {
  .header-transparent .rd-navbar-inner {
    position: absolute;
    left: 0;
    right: 0;
  }
  .header-transparent .btn {
    z-index: 1;
    color: #0e3655;
    background-color: #fff;
    border-color: #fff;
  }
  .header-transparent .btn:focus, .header-transparent .btn.focus, .header-transparent .btn:hover, .header-transparent .btn:active, .header-transparent .btn.active,
  .open > .header-transparent .btn.dropdown-toggle {
    color: #fff;
    background-color: #ea6c56;
    border-color: #ea6c56;
  }
  .header-transparent .btn:active, .header-transparent .btn.active,
  .open > .header-transparent .btn.dropdown-toggle {
    -webkit-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.15);
  }
  .header-transparent .btn:active:hover, .header-transparent .btn:active:focus, .header-transparent .btn:active.focus, .header-transparent .btn.active:hover, .header-transparent .btn.active:focus, .header-transparent .btn.active.focus,
  .open > .header-transparent .btn.dropdown-toggle:hover,
  .open > .header-transparent .btn.dropdown-toggle:focus,
  .open > .header-transparent .btn.dropdown-toggle.focus {
    color: #fff;
    background-color: #ea6c56;
    border-color: #ea6c56;
  }
  .header-transparent .btn.disabled, .header-transparent .btn[disabled],
  fieldset[disabled] .header-transparent .btn {
    pointer-events: none;
    opacity: .5;
  }
  .header-transparent .btn .badge {
    color: #fff;
    background-color: #0e3655;
  }
  .header-transparent .btn:focus {
    color: #0e3655;
    background-color: #fff;
  }
  .header-transparent .btn:focus:hover {
    background-color: #ea6c56;
    color: #fff;
  }
  .header-transparent .rd-navbar-nav > .rd-navbar-submenu.rd-navbar--has-dropdown .rd-navbar-submenu-toggle {
    position: relative;
    z-index: 1;
    color: #fff;
  }
}

.header-transparent .icon-darker {
  color: #fff;
}

.header-transparent .rd-navbar--is-stuck {
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.15);
}

.header-transparent .rd-navbar--is-stuck .rd-navbar-inner {
  position: static;
}

.header-transparent .rd-navbar--is-stuck .rd-navbar-inner {
  background-color: #43ca83;
}

.header-center .rd-navbar-fixed .li-only-fixed .address-fixed {
  display: none;
}

@media (min-width: 768px) {
  html:not(.tablet) .header-center .rd-navbar {
    box-shadow: none;
  }
  html:not(.tablet) .header-center .rd-navbar-search {
    bottom: -57px;
  }
  html:not(.tablet) .header-center .rd-navbar-telephone {
    display: block;
  }
  html:not(.tablet) .header-center .top-panel {
    display: none;
  }
  html:not(.tablet) .header-center .rd-navbar-inner {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 0;
  }
  html:not(.tablet) .header-center .rd-navbar-wrap {
    max-width: 1200px;
    margin: 0 auto;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  html:not(.tablet) .header-center .rd-navbar-nav-wrap {
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
    margin-top: 35px;
    padding: 10px 0;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-top: 1px solid #e4e4e4;
  }
  html:not(.tablet) .header-center .rd-navbar-nav {
    position: static;
  }
  html:not(.tablet) .header-center .rd-navbar-megamenu {
    left: 10px;
    width: 98%;
  }
}

@media (min-width: 768px) and (min-width: 1200px) {
  html:not(.tablet) .header-center .rd-navbar-megamenu {
    left: 0;
    width: 100%;
  }
}

@media (min-width: 768px) {
  html:not(.tablet) .header-center .rd-navbar-fullwidth {
    width: 100%;
  }
  html:not(.tablet) .header-center .rd-navbar-fullwidth .rd-navbar-nav > li + li {
    margin-left: 0;
  }
  html:not(.tablet) .header-center .rd-navbar--is-stuck {
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.15);
  }
  html:not(.tablet) .header-center .rd-navbar--is-stuck .rd-navbar-inner {
    padding: 15px;
  }
  html:not(.tablet) .header-center .rd-navbar--is-stuck .rd-navbar-telephone, html:not(.tablet) .header-center .rd-navbar--is-stuck .rd-navbar-panel, html:not(.tablet) .header-center .rd-navbar--is-stuck .btn {
    display: none;
  }
  html:not(.tablet) .header-center .rd-navbar--is-stuck .rd-navbar-nav-wrap {
    margin-top: 0;
    border: none;
    padding-top: 0;
    padding-bottom: 0;
  }
  html:not(.tablet) .header-center .rd-navbar-submenu .rd-navbar-megamenu, html:not(.tablet) .header-center .rd-navbar-submenu.focus .rd-navbar-megamenu {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  html:not(.tablet) .header-center .rd-navbar-nav > li + li {
    margin-left: 64px;
  }
}

.header-minimal .top-panel {
  display: none;
}

.header-minimal .btn {
  display: none;
}

@media (min-width: 768px) {
  .header-minimal .rd-navbar--is-stuck {
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.15);
  }
  .header-minimal .rd-navbar-static {
    width: 100%;
  }
  .header-minimal .rd-search {
    bottom: -54px;
  }
  .header-minimal .rd-navbar-wrap {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
  }
  .header-minimal .rd-navbar-nav-wrap {
    text-align: right;
    max-width: none;
    padding: 0;
  }
  .header-minimal .rd-navbar-nav {
    position: static;
  }
}

@media (min-width: 768px) and (min-width: 1200px) {
  .header-minimal .rd-navbar-nav {
    position: relative;
  }
}

@media (min-width: 768px) {
  .header-minimal .rd-navbar-megamenu {
    max-width: none;
    width: 98%;
  }
}

@media (min-width: 768px) and (min-width: 1200px) {
  .header-minimal .rd-navbar-megamenu {
    width: 100% !important;
    left: 0;
  }
}

@media (min-width: 1200px) {
  .header-minimal .rd-search {
    bottom: -93px;
  }
}

.header-minimal .rd-navbar-fullwidth .rd-navbar-megamenu {
  left: 0;
}

.header-minimal .rd-navbar-nav .rd-navbar-submenu .rd-navbar-megamenu, .header-minimal .rd-navbar-nav .rd-navbar-submenu.focus .rd-navbar-megamenu {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.brand-fixed, .telephone-fixed, .mail-fixed, .address-fixed, .list-inline-fixed {
  display: none;
}

.header-corporate .list-inline-fixed {
  display: block;
}

.header-corporate .list-inline-fixed .icon-xs-square {
  width: 25px;
}

.header-hamburger .rd-navbar-fixed .li-only-fixed .address-fixed {
  display: none;
}

.header-hamburger .btn {
  display: none;
}

.header-hamburger .li-only-fixed {
  padding-left: 16px;
}

@media (min-width: 768px) {
  .header-hamburger .li-only-fixed {
    padding-left: 0;
  }
  .header-hamburger .rd-navbar-search-toggle {
    display: none;
  }
  .header-hamburger .rd-navbar-brand {
    display: none;
  }
  .header-hamburger .rd-navbar-fixed .li-only-fixed .address-fixed {
    display: none;
  }
  .header-hamburger .li-only-fixed .rd-search {
    max-width: 100%;
  }
  .header-hamburger .rd-navbar-nav li .rd-navbar-dropdown > li {
    padding-left: 0;
  }
  .header-hamburger .rd-navbar-fixed .rd-navbar-nav-wrap {
    right: -432px;
    left: auto;
    overflow-y: scroll;
    top: 20px;
    bottom: 20px;
    -webkit-transform: translateX(-10%);
    transform: translateX(-10%);
    border-radius: 6px;
    box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.21);
    text-align: center;
    padding: 36px 30px 36px 41px;
  }
  .header-hamburger .rd-navbar-fixed .rd-navbar-nav-wrap.active {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    right: 0;
  }
  .header-hamburger .telephone-fixed, .header-hamburger .mail-fixed {
    display: block;
    text-align: left;
  }
  .header-hamburger .telephone-fixed a, .header-hamburger .mail-fixed a {
    color: #7f7f7f;
  }
  .header-hamburger .telephone-fixed a:hover, .header-hamburger .mail-fixed a:hover {
    color: #43ca83;
  }
  .header-hamburger .telephone-fixed {
    margin-top: 40px;
  }
  .header-hamburger .mail-fixed {
    margin-top: 10px;
  }
  .header-hamburger .rd-navbar-fixed .brand-fixed {
    display: inline-block;
    position: static;
    border-bottom: 1px solid #c1c1c1;
    padding: 0 32px 77px;
  }
  .header-hamburger .rd-navbar-fixed .brand-fixed .brand-name .brand-slogan {
    display: block;
  }
  .header-hamburger .btn {
    display: none;
  }
  .header-hamburger .rd-navbar-panel {
    padding: 0;
    height: 0;
  }
  .header-hamburger .rd-navbar-panel:before {
    background-color: transparent;
  }
  .header-hamburger .brand-static {
    display: none;
  }
  .header-hamburger .rd-navbar-nav-wrap {
    width: 300px;
    bottom: 0;
  }
  .header-hamburger .rd-navbar-search .form-group {
    position: static;
    visibility: visible;
    opacity: 1;
  }
  .header-hamburger .rd-navbar-nav {
    width: 100%;
    box-shadow: none;
    padding-top: 7px;
    height: 90%;
  }
  .header-hamburger .rd-navbar-nav > li > a {
    padding: 3px 12px 3px 0;
  }
  .header-hamburger .rd-navbar-nav .rd-navbar-submenu-toggle {
    right: 20px;
    top: 24px;
    bottom: inherit;
  }
  .header-hamburger .rd-navbar-panel-wrap {
    padding: 0;
  }
  .header-hamburger .rd-navbar-nav li > a {
    display: block;
  }
  .header-hamburger .rd-navbar-fixed .rd-navbar-submenu-toggle:after {
    top: 16px;
  }
  .header-hamburger .rd-navbar-toggle {
    position: absolute;
    background-color: #fff;
    transition: .3s;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    top: 60px;
    text-align: center;
    right: 75px;
    box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.15);
  }
  .header-hamburger .rd-navbar-toggle:before {
    position: absolute;
    top: 7px;
    left: 17px;
    content: '\f0c9';
    font-size: 30px;
    font-family: "FontAwesome";
    color: #0e3655;
  }
  .header-hamburger .rd-navbar-toggle span {
    display: none;
  }
  .header-hamburger .rd-navbar-toggle:hover {
    background-color: #43ca83;
  }
  .header-hamburger .rd-navbar-toggle:hover:before {
    color: #fff;
  }
  .header-hamburger .rd-navbar-toggle.active {
    transition: .4s all ease;
    -webkit-transform: translateX(-250px);
    transform: translateX(-250px);
  }
  .header-hamburger .rd-navbar-toggle.active:before {
    top: 6px;
    left: 18px;
    content: "\f00d";
    color: #0e3655;
  }
}

.tablet .rd-navbar-telephone, .mobile .rd-navbar-telephone {
  display: none;
}
